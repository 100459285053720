<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Puppy Party</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.partyForm.openForm(litter)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Puppy Party</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Date</th>
                  <td>
                    {{
                      litter.puppy_party_date
                        ? formatDate(litter.puppy_party_date)
                        : ""
                    }}
                  </td>
                </tr>

                <tr>
                  <th>Start Time</th>
                  <td>{{ litter.puppy_party_start_time }}</td>
                </tr>

                <tr>
                  <th>End Time</th>
                  <td>{{ litter.puppy_party_end_time }}</td>
                </tr>

                <tr>
                  <th>Event URL</th>
                  <td>{{ litter.puppy_party_event_url }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Collection</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.collectionForm.openForm(litter)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Collection</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Collection Date</th>
                  <td>
                    {{
                      litter.collection_date
                        ? formatDate(litter.collection_date)
                        : ""
                    }}
                  </td>
                </tr>

                <tr>
                  <th>Event URL</th>
                  <td>{{ litter.collection_event_url }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <PartyForm ref="partyForm" />
      <CollectionForm ref="collectionForm" />
    </v-container>
  </div>
</template>

<script>
import PartyForm from "../components/visits/PartyForm";
import CollectionForm from "../components/visits/CollectionForm";

export default {
  components: { PartyForm, CollectionForm },

  data() {
    return {};
  },

  computed: {
    litter() {
      return this.$store.getters["lhl/litters/get"];
    },
  },
};
</script>
