<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Puppy Party</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="party-form">
          <v-text-field
            label="Date"
            v-model="fields.puppy_party_date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('puppy_party_date')"
            :error-messages="errors['puppy_party_date']"
          ></v-text-field>

          <v-text-field
            label="Start Time"
            v-model="fields.puppy_party_start_time"
            type="time"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('puppy_party_start_time')"
            :error-messages="errors['puppy_party_start_time']"
          ></v-text-field>

          <v-text-field
            label="End Time"
            v-model="fields.puppy_party_end_time"
            type="time"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('puppy_party_end_time')"
            :error-messages="errors['puppy_party_end_time']"
          ></v-text-field>

          <v-text-field
            label="Event URL"
            v-model="fields.puppy_party_event_url"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('puppy_party_event_url')"
            :error-messages="errors['puppy_party_event_url']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="party-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        puppy_party_date: null,
        puppy_party_start_time: null,
        puppy_party_end_time: null,
        puppy_party_event_url: null,
      },

      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  computed: {
    dams() {
      return this.$store.getters["lhl/dogs/dams"];
    },
  },

  methods: {
    openForm: function (litter = null) {
      if (litter !== null) {
        this.litter = litter;
        this.puppy_party_date = litter.puppy_party_date;
        this.puppy_party_start_time = litter.puppy_party_start_time;
        this.puppy_party_end_time = litter.puppy_party_end_time;
        this.puppy_party_event_url = litter.puppy_party_event_url;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        litterId: this.$route.params.litterId,
        fields: this.fields,
      };

      payload.fields.formName = "party";

      this.$store
        .dispatch("lhl/litters/saveBasic", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.dam_id = null;
    },
  },
};
</script>
